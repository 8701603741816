<template>
	<div class="roleCheck">
		<div class="titleTxt">文档管理</div>
		<div class="column-me">
			<div class="table-border">
				<el-table :data="tableData" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column prop="name" label="文档名称" align="center"></el-table-column>
					<el-table-column prop="file_format" label="文档类型" align="center"></el-table-column>
					<el-table-column label="操作" align="center">
						<template #default="scope">
							<el-button type="text" size="small" @click="clickDownload(scope.row)">查看/下载</el-button>
							<!-- <el-button type="text" size="small" @click="clickDownload(scope.row)">下载</el-button> -->
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="row-me row-center flex-end1 margin-top30" v-if="tableData.length > 0">
				<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
					:current-page.sync="pageNo" :total="total">
				</el-pagination>
			</div>

			<!-- <div class="row-me row-center flex-end1 margin-top10">
				<el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				tableData: [],
				pageNo: 1,
				total: 0,
			}
		},
		// 侦听器
		watch: {},
		// 计算属性
		computed: {},
		// html加载完成之前
		created() {
			this.clickSearch()
		},
		// html加载完成后执行。
		mounted() {

		},
		// 事件方法执行
		methods: {
			clickDownload(row) {
				window.open(row.file_url)
			},
			clickSearch() {
				this.pageNo = 1
				this.getData()
			},
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getData()
			},
			//获取数据
			getData() {
				this.$post('/index/documentListInfo', {
					page: this.pageNo,
				}).then((res) => {
					if (res) {
						this.total = res.data.allListNumber;
						this.tableData = res.data.listInfo;
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.roleCheck {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;

		.titleTxt {
			font-size: 24px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #252630;
			padding-bottom: 30px;
		}

		.agains {
			margin-left: 10px;
			width: 100px;
			height: 40px;
			font-size: 16px;
		}

		.searchs {
			margin-left: 10px;
			font-size: 16px;

			img {
				width: 16px;
				height: 16px;
				margin-right: 10px;
			}
		}

		.title {
			font-size: 25px;
			font-weight: bold;
		}

	}
</style>